import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoMdOpen,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box, Button } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { AboutCard } from "../components/site/";

import PayHeroGraphic from "../images/graphics/PayHeroGraphic_Cropped.svg";
import Logo from "../images/logos/PayHero/PayHero_Full.svg";
import FromFlexiTime from "../images/logos/FlexiTime/FromFlexiTime_Horiz.svg";

const HeroContainer = styled(Container)`
  background: url(${PayHeroGraphic});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colours.white};
    top: 0;
    opacity: 0.6;
  }
`;

const TeamList = styled(Row)`
  --item-gap: 40px;
  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 4) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: calc((100% / 2) - var(--item-gap));
      margin-right: 0;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const About = (props) => {
  const team = [
    {
      image: "AlexGiurioli.jpg",
      name: "Alex Giurioli",
      role: "Support",
      team: "Customer Success",
    },
    {
      image: "AmyBroeders.jpg",
      name: "Amy Broeders",
      role: "Development",
      team: "Development",
    },
    {
      image: "CharlesAllen.jpg",
      name: "Charles Allen",
      role: "Development",
      team: "Development",
    },
    {
      image: "CharlieSaxton.jpg",
      name: "Charlie Saxton",
      role: "Development",
      team: "Development",
    },
    {
      image: "DanielDAlessio.jpg",
      name: "Daniel D'Alessio",
      role: "Support",
      team: "Customer Success",
    },
    {
      image: "EmilyDaly.jpg",
      name: "Emily Daly",
      role: "Marketing",
      team: "Discovery",
    },
    // {
    //   image: "GinaMurphy.jpg",
    //   name: "Gina Murphy",
    //   role: "Product Lead",
    //   team: "Product",
    // },
    {
      image: "HarrisonBlackburnChurcher.jpg",
      name: "Harrison Blackburn Churcher",
      role: "Development",
      team: "Development",
    },
    {
      image: "HarryTaylor.jpg",
      name: "Harry Taylor",
      role: "Development",
      team: "Development",
    },
    {
      image: "JakeHarvey.jpg",
      name: "Jake Harvey",
      role: "Chief Growth Officer",
      team: "Discovery",
    },
    {
      image: "JamesBarber.jpg",
      name: "James Barber",
      role: "Design",
      team: "Product",
    },
    // {
    //   image: "JesseWeir.jpg",
    //   name: "Jesse Weir",
    //   role: "Development",
    //   team: "Development",
    // },
    {
      image: "JillMcCorkindale.jpg",
      name: "Jill McCorkindale",
      role: "Accounts & Support",
      team: "Customer Success",
    },
    {
      image: "KeelyHaskett.jpg",
      name: "Keely Haskett",
      role: "Development",
      team: "Development",
    },
    // {
    //   image: "LizzieWild.jpg",
    //   name: "Lizzie Wild",
    //   role: "Marketing",
    //   team: "Discovery",
    // },
    {
      image: "MatthewIrving.jpg",
      name: "Matthew Irving",
      role: "Support",
      team: "Customer Success",
    },
    {
      image: "MichaelGordon.jpg",
      name: "Michael Gordon",
      role: "Development",
      team: "Development",
    },
    {
      image: "Support_1.jpg",
      name: "Nicky Clark",
      role: "Customer Success Manager",
      team: "Customer Success",
    },
    {
      image: "NicolaHallberg.jpg",
      name: "Nicola Hallberg",
      role: "Development",
      team: "Development",
    },
    {
      image: "PeteAshby.jpg",
      name: "Pete Ashby",
      role: "Head of Discovery",
      team: "Discovery",
    },
    {
      image: "RobbieFraser.jpg",
      name: "Robbie Fraser",
      role: "Development Manager",
      team: "Development",
    },
    {
      image: "RobOwen.jpg",
      name: "Robert Owen",
      role: "CEO",
      team: "Product",
    },
    {
      image: "ShaunBennet.jpg",
      name: "Shaun Bennett",
      role: "Product Advocate",
      team: "Discovery",
    },
    {
      image: "ShawniHadfield.jpg",
      name: "Shawni Hadfield",
      role: "CFO",
      team: "Discovery",
    },
  ];

  return (
    <Layout>
      <Seo
        title="The PayHero Team | About Us"
        description="Work. Life. Success.™ That's what makes us tick - and we’re committed to helping NZ business owners achieve all three. Find out about the team at PayHero."
        pathname={props.location.pathname}
      />
      <Container className="primary">
        <Wrapper stackGap={80}>
          <h2 className="-textCenter -center" css={{ maxWidth: "800px" }}>
            We're PayHero 👋, a team with a focus on helping New Zealand
            businesses pay their employees right.
          </h2>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper noPaddingBottom>
          <Box stackGap={7}>
            <img
              className="-center"
              src={Logo}
              alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
              height="45"
            />
            <img
              className="-center"
              src={FromFlexiTime}
              alt="PayHero | A FlexiTime Product"
              height="22"
            />
          </Box>
          <Row stackGap={80} alignCenter>
            <Box stackGap={10} size={50}>
              <h5 className="-fontPrimary">WHY WE DO WHAT WE DO</h5>
              <h2>Pay Employees Right</h2>
              <p>
                Our mission is simple. We want to help New Zealand companies pay
                employees right. That way employees get the entitlements they
                deserve and employers can do what they do, without having to
                worry about payroll and compliance.
              </p>
              <p>
                Though it sounds easy, recent history has shown that getting
                payroll right is easier said than done, with large organisations
                and government agencies forced to pay millions to cover
                historical employee underpayments.
              </p>
              <p>
                Following the very latest{" "}
                <Link to="/holidays-act-compliance">Holidays Act guidance</Link>{" "}
                from MBIE, PayHero stores employee leave balances in weeks so
                companies with staff who work variable hours or have changing
                work patterns always get their leave entitlements correct.
              </p>
              <p>
                PayHero gives you the tools you need to track employee time and
                calculate accurate holidays and leave. In short, it’s payroll
                that you and your employees can trust.
              </p>
            </Box>
            <Box size={50}>
              <Image
                alt="PayHero Team | Office Photo"
                filename="FlexiTeam_2022.jpg"
                addShadow
                rounded
              />
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper noPaddingBottom>
          <Row stackGap={80} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="PayHero Team | ABV 2021 Photo"
                filename="FlexiTime_ABV2021.jpg"
                addShadow
                rounded
              />
            </Box>
            <Box stackGap={10} size={50}>
              <h5 className="-fontPrimary">WHERE WE’VE COME FROM</h5>
              <h2>A brief history</h2>
              <p>
                PayHero is brought to you by the team at{" "}
                <a href="https://www.flexitime.works" target="_blank">
                  FlexiTime
                </a>
                . We’ve been making it easy for Kiwis to work and get paid since
                2008. Back then our founders Rob, Sam and Jill got together and
                built FlexiTime Payroll, one of the first cloud based payroll
                systems in New Zealand.
              </p>
              <p>
                Since then, we’ve built up a reputation as the go-to provider
                for businesses with complex payroll requirements - especially
                those with casual, part-time and remote employees.
              </p>
              <p>
                Fast forward ten years and it was time for a refresh. In 2019
                our fast growing team in Wellington launched a new and improved
                payroll system - PayHero. It’s the culmination of everything
                we’ve learned over a decade of helping thousands of customers
                get their payroll right.
              </p>
              <p>
                Though we’re experts when it comes to companies with tricky
                payroll requirements, businesses from every industry choose
                PayHero for our time tracking tools, employee self-service,
                advanced reporting, smart integrations, expert support and easy
                payment options.
              </p>
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} alignCenter>
            <Box stackGap={10} size={50}>
              <h5 className="-fontPrimary">WHAT WE BELIEVE</h5>
              <h2>Work Better</h2>
              <p>
                We believe that work should be great for everyone. But in
                today’s workplaces business owners, people managers and
                employees spend too much time on admin and paperwork, which
                kills productivity.
              </p>
              <p>
                We know there’s another way. We’re obsessed with helping
                businesses work better.
              </p>
              <p>
                With the right software tools backing you up you can automate
                and streamline many of the traditionally complex, admin
                intensive or tedious day-to-day tasks and spend your valuable
                time on what’s really important. Smart use of technology allows
                you to provide a more human experience for your staff and that
                way everyone wins.
              </p>
              <p>
                Everything we do is designed to make it easier to run a business
                and to remove the administrative burden on business owners and
                people managers.
              </p>
            </Box>
            <Box size={50} stackGap={70}>
              <Image
                alt="PayHero Team | Xmas 2020 Photo"
                filename="FlexiTimePic_Xmas2020.jpg"
                addShadow
                rounded
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">Our Team</h2>
          <TeamList justify="flex-start" isWrap>
            {team.map((item, i) => {
              return (
                <AboutCard
                  image={item.image}
                  name={item.name}
                  role={item.role}
                  team={item.team}
                />
              );
            })}
          </TeamList>
          <Box className="-center" size={70}>
            <h3 css={{ color: "#333" }} className="-fontLight -textCenter">
              Want to join our fun, high performing team?{" "}
              <a href="https://flexitime.bamboohr.com/careers" target="_blank">
                Check out our careers page
              </a>{" "}
              for current vacancies - we’re always keen to hear from talented
              people with great ideas who love a challenge!
            </h3>
          </Box>
          <Box className="-textCenter" stackGap={20}>
            <h5 css={{ color: "#999" }}>Follow Us:</h5>
            <Row stackGap={15} justify="center" noBreak>
              <a
                href="https://www.facebook.com/PayHeroHQ/"
                target="_blank"
                rel="noopener noreferrer"
                css={{ fontSize: "2rem" }}
                aria-label="Follow us on Facebook"
              >
                <IoLogoFacebook />
              </a>
              {/* <a
                href="https://twitter.com/FlexiTimeTeam"
                target="_blank"
                rel="noopener noreferrer"
                css={{ fontSize: "2rem" }}
                aria-label="Follow us on Twitter"
              >
                <IoLogoTwitter />
              </a> */}
              <a
                href="https://www.linkedin.com/showcase/payheronz/"
                target="_blank"
                rel="noopener noreferrer"
                css={{ fontSize: "2rem" }}
                aria-label="Follow us on LinkedIn"
              >
                <IoLogoLinkedin />
              </a>
              {/* <a
                href="https://instagram.com/flexitimehq"
                target="_blank"
                rel="noopener noreferrer"
                css={{ fontSize: "2rem" }}
                aria-label="Follow us on Instagram"
              >
                <IoLogoInstagram />
              </a> */}
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="darkBlue" className="-textCenter">
        <Wrapper>
          <Box stackGap={40}>
            <div>
              <h2 css={{ color: "#fff" }}>News & Media</h2>
              <h4 css={{ color: "#fff" }}>
                See PayHero in the news and download logos & other images.
              </h4>
            </div>
            <Button
              className="dark -lg -center"
              to="https://www.flexitime.works/media"
              atag
              externalLink
            >
              Visit Media <IoMdOpen css={{ top: "2px", marginLeft: "4px" }} />
            </Button>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default About;
